export enum RouteName {
  activityView = '/activity',
  auctionsView = '/auctions',
  home = '/',
  marketplace = '/marketplace',
  marketplaceMint = '/marketplace/:tokenMint',
  sell = '/sell',
  drop = '/drop',
  customToken = '/custom-token-marketplace',
  multipleCollection = '/multi-collection-marketplace',
  marketplaceWithUrl = '/marketplace-with-url',
  external1 = '/stake',
  external2 = '/marketsol',
}
